













































import { Component, Vue } from 'vue-property-decorator';
import Project from '@/classes/project';
import projectList from '@/content/project.list';

/* eslint-disable @typescript-eslint/no-var-requires */
const isotope = require('vueisotope');
const imagesLoaded = require('vue-images-loaded');

@Component({
  components: {
    isotope,
  },
  directives: {
    imagesLoaded,
  },
})
export default class Portfolio extends Vue {
  private list = projectList;

  private filterOption = '*';

  private options = {
    isFitWidth: true,
    getFilterData: {
      '*': () => true,
      SoftEng: (project: Project) => project.types.includes('SoftEng'),
      Data: (project: Project) => project.types.includes('Data'),
      AI: (project: Project) => project.types.includes('AI'),
      Music: (project: Project) => project.types.includes('Music'),
      Art: (project: Project) => project.types.includes('Art'),
    },
  };

  private filter(key: string) {
    (this.$refs.portfolioGrid as typeof isotope).filter(key);
  }

  private layout() {
    (this.$refs.portfolioGrid as typeof isotope).layout('masonry');
  }

  private getImgUrl(fileName: string) {
    const images = require.context('@/assets/images/', true);
    return images(`./${fileName}`);
  }

  private scrollAtTop() {
    window.scrollTo(0, 0);
  }
}
